<template>
    <div class="pageBox">
        <div class="topBox">
            <el-radio-group v-model="radioType" @change="radioTypeChange" size="large">
				<el-radio-button :label="3">首页</el-radio-button>
                <el-radio-button :label="0">案例库</el-radio-button>
                <el-radio-button :label="1">资料库</el-radio-button>
                <el-radio-button :label="2">应用指南</el-radio-button>
            </el-radio-group>
        </div>
        
        <div class="topBox">
            <el-tree-select 
             v-model="query.types" 
             size="large"
             node-key="id"
             :props="defaultProps" 
             :data="data" 
             @change="changeTree"
             :render-after-expand="false" />
            <el-button  style="margin-left: 10px;" color="#803a15" dark @click="oper(0,null)">
                <svg-icon icon-class="plus"/>&nbsp;&nbsp;添加
            </el-button>
        </div>
        <div>
            <el-table 
            size="large" 
            :data="tableData" 
            style="width: 100%">
                <el-table-column prop="title" label="标题" align="center" />
                <el-table-column prop="typename" label="类型"  align="center" />
                <el-table-column prop="provider" label="创建者" align="center" />
                <el-table-column prop="sourcename" label="来源"  align="center" />
                <el-table-column prop="sourcename" label="状态"  align="center">
                    <template #default="scope">
                        <el-tag v-if="scope.row.publish==1" type="success">已发布</el-tag>
                        <el-tag v-else type="danger">未发布</el-tag>
                        
                    </template>
                </el-table-column>
                <el-table-column prop="createtime" label="创建时间" align="center" />
                <el-table-column label="操作" align="center">
                    <template #default="scope">
                        <el-button type="primary" size="small" plain @click="this.$refs.preView.open(scope.row)">预览</el-button>
                        <el-button type="success" size="small" plain @click="oper(1,scope.row)">编辑</el-button>
                        <el-button type="danger" size="small" @click="del(scope.row.id)" plain>删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination 
            background 
            :page-size="query.size"
            :current-page="query.page"
            layout="total, prev, pager, next"
            @current-change="pageChange" 
            :total="total" />
        </div>
        <operForm ref="operForm" @OK="operOk" :dataTree="data"/>
        <preView ref="preView"  @OK="operOk"/>
    </div>
</template>
<script>
import operForm from './Modules/oper.vue'
import preView from './Modules/preView.vue'
import { getTreeList, getResource, delFun} from '@/api/resource'
export default {
    components:{
        operForm,
        preView
    },
    data(){
        return{
            data:[],
            radioType:3,
            query:{
                page: 1,
                size: 10,
                types:null,
            },
            total:0,
            tableData:[],
            treeList:[],
            defaultProps:{
                children: 'children',
                label: 'name',
            }
        }
    },
    mounted(){
        this.GetTreeList()
    },
    methods:{
        GetTreeList(){
            getTreeList().then(r=>{
              this.treeList = r.data 
              this.radioTypeChange(0)
            })
        },
        radioTypeChange(val){
            this.radioType = val
            this.data = this.treeList[this.radioType].children 
            if(this.data[0].children.length>0){
                this.query.types = this.data[0].children[0].id
            }else{
                this.query.types = this.data[0].id
            }
            this.GetList()
        },
        changeTree(id){
            this.query.types = id
            this.GetList()
        },
        GetList(){
            getResource(this.query).then(r=>{
                if(r.code == 200){
                    this.tableData = r.data.list
                    this.total = r.data.total
                }
                
            })
        },
        pageChange(page){
            this.query.page = page
            this.GetList()
        },
        oper(type,data){
            this.$refs.operForm.open(type,data)
        },
        del(id){
            this.$confirm(
                '此操作将永久删除该信息，是否继续?',
                'Warning',
                {
                confirmButtonText: '确认',
                cancelButtonText: '取消',
                type: 'warning',
                }
            ).then(() => {
                delFun(id).then(r=>{
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.query.page = 1
                    this.GetList()
                })
            })
            .catch(() => {
                
            })
        },
        operOk(type){
            if(type == 0){
                this.query.page = 1
            }
            this.GetList()
        }
    }
}
</script>
<style lang="scss" scoped>
.topBox{
    width: 100%;
    margin-bottom: 20px;
}
.pageBox{
    width: 100%;
    padding: 20px 20px;
    box-sizing: border-box;
}
.el-pagination{
    margin-top: 20px;
    justify-content: center;
}
/deep/.el-radio-button__original-radio:checked+.el-radio-button__inner{
    background-color:#803a15; 
    border-color:#803a15;
    box-shadow: -1px 0 0 0 #803a15;
}
/deep/.el-pagination.is-background .el-pager li.is-active{
    background-color: #803a15;
}
</style>