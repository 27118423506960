<template>
    <el-dialog
    v-model="dialogVisible"
    title="预览"
    width="80%"
    >    
    <div class="pageDetil">
        <h1>{{ data.title}}</h1>
        <div class="tips">
            <div class="line"></div>
            <div class="centerLine">
                <div>
                    时间：{{ data.createtime}}
                </div>
                <div style="margin-left: 50px;">
                    来源：{{ data.sourcename }}
                </div>
            </div>
            <div class="line"></div>
        </div>
        <div v-html="data.context">

        </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button color="#803a15" dark  @click="updateStatusFun">{{data.publish==1?'撤销':''}}发布</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { updateStatus } from '@/api/resource.js'
export default {
    emits:['OK'],
    data(){
        return{
            data:{},
            dialogVisible:false
        }
       
    },
    methods:{
        open(data){
            this.data = data
            this.dialogVisible = true
        },
        updateStatusFun(){
            updateStatus(this.data.id).then(r=>{
                if(r.code == 200){
                    let string = this.data.publish==1?'撤销成功':'发布成功'
                    this.$message.success(string)
                    this.dialogVisible = false
                    this.$emit('OK', 1)
                }
            })
        }
    }
    }
</script>
<style lang="scss" scoped>
.pageDetil{
    width: 100%;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    padding: 20px;
    h1{
        text-align: center;
    }
}
.tips{
    display: flex;
    justify-content: space-around;
    align-items: center;
    line-height: 50px;
    margin-bottom: 40px;
    .line{
        flex: 1;
        height: 1px;
        background-color: #e9e9e9;
    }
    .centerLine{
        min-width: 300px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #666;
    }
}
</style>